<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" class="dialogAgentedit">
    <div class="title">编辑代理</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="form">
      <el-form ref="form" :model="form" label-width="140px">
        <el-form-item label="姓名：" prop="distributorName" required>
          <el-input v-model="form.distributorName" class="input-width" disabled></el-input>
        </el-form-item>
        <el-form-item label="账号：" prop="mobile" required>
          <el-input v-model="form.mobile" class="input-width" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属地区：" prop="city">
          <el-cascader
            ref="myCascader"
            v-model="form.city"
            :options="total"
            class="input-width"
            placeholder="请选择证书归属地"
            @change="handleChangeAddress"
          >
          </el-cascader
        ></el-form-item>
        <el-form-item ref="appendixUrl" label="相关资质：" prop="appendixUrl">
          <upload-image v-model="form.appendixUrl" :upload-img="$refs.appendixUrl"></upload-image>
          <div class="extension">
            单个文件大小5MB，支持扩展名：.png .jpg .jpeg（可上传一个文件）
          </div>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model.trim="form.remark"
            class="input-height"
            placeholder="请输入请输入备注"
            type="textarea"
            maxlength="32"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="btngroup">
      <div class="btns">
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import UploadImage from '@/components/UploadImage'
import total from '@/assets/address/country'
import { editAgentInfo } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'DialogAgentedit',
  components: {
    UploadImage,
  },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      form: {
        city: [],
      },
      total,
      city: [],
    }
  },
  methods: {
    show(row) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.form = Object.assign({}, row)
        this.city = [row.provinceCode, row.cityCode]
        this.form.city = this.city
      })
      console.log(this.form)
    },
    handleChangeAddress(val) {
      this.form.provinceCode = val[0]
      this.form.provinceName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0]
      this.form.cityName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1]
      this.form.cityCode = val[1]
      this.form.city = val
    },
    async submit() {
      if (this.form.remark != '' && this.form.remark.length < 2) {
        this.$message.warning('请输入2-32备注')
        return
      }
      this.submitLoading = true
      const [, err] = await to(editAgentInfo({ ...this.form }))
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('编辑成功')
      this.dialogVisible = false
      this.$emit('editsuccess')
    },
  },
}
</script>
<style lang="scss" scoped>
.dialogAgentedit {
  ::v-deep.el-dialog {
    width: 630px;
    height: 703px;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 19px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 54px;
      line-height: 54px;
      border-bottom: 1px solid #e2e2e2;
      padding-left: 20px;
      font-size: 16px;
      color: #333333;
    }
    .form {
      margin-top: 20px;
      .uploader .el-upload {
        width: 160px;
        height: 120px;
      }
      .input-width {
        width: 360px;
        height: 40px;
      }
      .input-height {
        width: 360px;
        .el-textarea__inner {
          resize: none;
          height: 180px;
        }
      }
      .extension {
        color: #909399;
        font-size: 12px;
        line-height: 12px;
      }
    }
    .btngroup {
      display: flex;
      justify-content: center;
      margin-top: 30px;
    }
  }
}
</style>
