<template>
  <div class="agentList">
    <table-list
      title="代理列表"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="agentList"
      :columns="columns(this)"
      :operates="operates(this)"
      :btns="btns(this)"
      :options="{
        selection: true,
        operatesWidth: 170,
        operatesSubtitle: operatesSubtitle,
        operatesText: operatesText,
      }"
      :pager="pager"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <DialogAddagent ref="dialogAddagent" @success="handleAgentList()"></DialogAddagent>
    <DialogEditagent ref="dialogEditagent" @editsuccess="handleAgentList()"></DialogEditagent>
  </div>
</template>
<script>
const operatesSubtitle = '冻结说明：'
const operatesText =
  '操作冻结后，代理商将无法进行分销，若想让分销商继续分销，可以操作恢复，请谨慎操作'
// 顶部按钮
const btns = _this => [
  {
    label: '添加代理',
    type: 'primary',
    method: _this.handleAddagent,
  },
  {
    label: '导出',
    method: _this.handleExporttable,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: 'ID',
    prop: 'id',
  },
  {
    label: '代理名称',
    prop: 'distributorName',
  },
  {
    label: '代理账号',
    prop: 'account',
  },
  {
    placeholder: '所属地区',
    type: 'treeSelect',
    prop: 'address',
    showLevels: true,
    options: _this.total,
    optionProps: {
      value: 'label',
      children: 'children',
    },
  },
  {
    placeholder: '代理级别',
    type: 'select',
    prop: 'agencyLevel',
    children: [
      { value: 1, label: '一级' },
      { value: 2, label: '二级' },
    ],
  },
  {
    type: 'picker',
    prop: 'dateTime',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 50,
  },
  {
    prop: 'distributorName',
    label: '代理名称',
    minWidth: 106,
  },
  {
    prop: 'mobile',
    label: '账号',
    minWidth: 115,
  },
  {
    prop: 'location',
    label: '所属地区',
    minWidth: 136,
    formatter: row => {
      if (row.location.length == 0) {
        return '—'
      } else if (row.location.length > 0 && row.location.length <= 8) {
        return row.location
      } else if (row.location.length > 8) {
        return row.location.slice(0, 8) + '...'
      }
    },
  },
  {
    prop: 'agencyLevel',
    label: '代理级别',
    minWidth: 76,
    formatter: row => {
      return { 1: '一级', 2: '二级' }[row.agencyLevel]
    },
  },
  {
    prop: 'createTime',
    label: '添加时间',
    sortdata: 1,
    minWidth: 150,
  },
  {
    prop: 'orderCount',
    label: '累计分销订单数',
    sortdata: 2,
    align: 'right',
    minWidth: 146,
  },
  {
    prop: 'orderAmount',
    label: '累计分销金额',
    sortdata: 3,
    align: 'right',
    minWidth: 146,
    formatter: row => {
      return '￥' + row.orderAmount
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleCourse,
  },
  {
    label: '销售详情',
    method: _this.handleSellRecord,
  },
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import total from '@/assets/address/country'
import DialogAddagent from './components/dialogAddagent'
import DialogEditagent from './components/dialogedit'
import { searchAgentList, deleteAgent, exportAgentList } from '@/api/certificate'
import to from 'await-to'
export default {
  components: {
    TableList,
    DialogAddagent,
    DialogEditagent,
  },
  data() {
    return {
      operatesSubtitle,
      operatesText,
      searchForm,
      columns,
      operates,
      btns,
      loading: false,
      queryParams: {},
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      agentList: [],
      selectAgentList: [],
      total,
      pickData: {},
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
    }
  },
  mounted() {
    this.handleAgentList()
  },
  methods: {
    async handleAgentList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(searchAgentList({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.agentList = res.data.list
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      if (queryParams.address && queryParams.address !== 0) {
        this.queryParams.provinceName = queryParams.address[0]
        this.queryParams.cityName = queryParams.address[1]
      } else {
        this.queryParams.provinceName = ''
        this.queryParams.cityName = ''
      }
      if (queryParams.dateTime && queryParams.dateTime !== 0) {
        this.queryParams.startTime = queryParams.dateTime[0]
        this.queryParams.endTime = queryParams.dateTime[1]
      } else {
        this.queryParams.startTime = ''
        this.queryParams.endTime = ''
      }
      this.handleAgentList()
    },
    //添加代理
    handleAddagent() {
      this.$refs.dialogAddagent.show()
    },
    //导出
    async handleExporttable() {
      let ids = []
      this.selectAgentList.map(item => {
        ids.push(item.id)
      })
      const { current, size } = this.pager
      const [res, err] = await to(exportAgentList({ ids: ids, current, size, ...this.queryParams }))
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '代理列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    //编辑
    handleCourse(row) {
      this.$refs.dialogEditagent.show(row)
    },
    //查看详情
    handleSellRecord(row) {
      this.$router.push({
        path: '/scenter/agentDetail',
        query: {
          distributorUserId: row.userId,
        },
      })
    },
    //删除
    async handleDelete(row) {
      this.$confirm('您确定要删除所选代理商吗？删除后将不可进行分销！', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const [, err] = await to(deleteAgent({ id: row.id }))
          if (err) return this.$message.warning(err.msg)
          this.$message.success('删除成功')
          this.handleAgentList()
        })
        .catch(() => {})
    },
    // 多选
    handleSelectionChange(val) {
      this.selectAgentList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleAgentList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleAgentList()
    },
  },
}
</script>
<style scoped lang="scss"></style>
