<template>
  <el-dialog :close-on-click-modal="false" :visible.sync="dialogVisible" class="dialogAgentAdd">
    <div class="title">从人员列表选择</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="search">
      <span class="item_subtitle">已选总人数：{{ SelectUserDataList.length }}人</span>
      <div class="item_search">
        <el-input v-model="name" placeholder="请输入姓名查找" class="item_input"></el-input>
        <el-button type="primary" class="item_btn" @click="handleSearchStaff">搜 索</el-button>
      </div>
    </div>
    <div class="PeopelTable">
      <table-list
        :loading="loading"
        :data="UserDataList"
        :columns="columns(this)"
        :options="{ selection: true, height: 420, selectable: selectEvent }"
        @selectionChange="handleSelectionChange"
      ></table-list>
    </div>
    <div class="btngroup">
      <div class="btns">
        <el-button :loading="submitLoading" type="primary" @click="submit">确定</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '学员名称',
    align: 'center',
    width: 430,
  },
  {
    prop: 'mobile',
    label: '手机号码',
    minWidth: 105,
  },
]
import TableList from '@/components/TableList'
import { searchStaffList, addAgent } from '@/api/certificate'
import to from 'await-to'
export default {
  name: 'AgentAdd',
  components: {
    TableList,
  },
  data() {
    return {
      dialogVisible: false,
      submitLoading: false,
      loading: false,
      UserDataList: [],
      SelectUserDataList: [],
      columns,
      name: '',
    }
  },
  methods: {
    show() {
      this.dialogVisible = true
      this.handleStaffData()
    },
    //搜索全部人员列表
    async handleStaffData() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(searchStaffList({ name: this.name }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.UserDataList = res.data
    },
    selectEvent(row) {
      return row.isPresent == 0
    },
    //模糊查询
    handleSearchStaff() {
      this.handleStaffData()
    },
    //多选
    handleSelectionChange(val) {
      this.SelectUserDataList = val
    },
    async submit() {
      if (!this.SelectUserDataList.length) return this.$message.warning('请先选择要添加的代理商')
      let UserInfo = []
      this.SelectUserDataList.forEach(item => {
        let obj = {}
        obj.distributorName = item.studentName
        obj.mobile = item.mobile
        obj.studentId = item.studentId
        obj.userId = item.userId
        UserInfo.push(obj)
      })
      console.log(UserInfo)
      this.submitLoading = true
      const [, err] = await to(addAgent(UserInfo))
      this.submitLoading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.dialogVisible = false
      this.$emit('success')
    },
  },
}
</script>
<style lang="scss" scoped>
.dialogAgentAdd {
  ::v-deep.el-dialog {
    width: 630px;
    height: 698px;
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 19px;
      top: 10px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 54px;
      line-height: 54px;
      background: #f6f6f6;
      border-bottom: 1px solid #e2e2e2;
      padding-left: 20px;
      font-size: 16px;
      color: #333333;
    }
    .search {
      display: flex;
      justify-content: space-between;
      .item_subtitle {
        padding: 29px 0 0 32px;
      }
      .item_search {
        display: flex;
        margin-top: 14px;
        margin-right: 35px;
        .item_input {
          width: 256px;
          height: 40px;
          margin-right: 9px;
        }
        .item_btn {
          width: 84px;
          height: 40px;
          background: #ff7b33;
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    .PeopelTable {
      margin: 20px 10px;
      height: 420px;
      .table-list {
        .el-table th {
          background: #fafafa;
          height: 60px;
        }
        .el-table tr {
          background-color: #fff !important;
          height: 60px;
        }
        .el-table--striped .el-table__body tr.el-table__row--striped td {
          background-color: #fafafa !important;
        }
        .el-table__row:nth-child(2n) {
          background-color: #fafafa !important;
        }
      }
    }
    .btngroup {
      display: flex;
      justify-content: center;
      margin-top: 60px;
    }
  }
}
</style>
